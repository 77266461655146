import Cookies from "js-cookie";
import { CONSENT_COOKIES_KEYS } from "./constants";
var Consents = {
    get: function () {
        var social = Cookies.get(CONSENT_COOKIES_KEYS.SOCIAL) === "true";
        var advertisement = Cookies.get(CONSENT_COOKIES_KEYS.ADVERTISEMENT) === "true";
        var analytics = Cookies.get(CONSENT_COOKIES_KEYS.ANALYTICS) === "true";
        var personalised_ads = Cookies.get(CONSENT_COOKIES_KEYS.PERSONALISED_ADS) === "true";
        var location = Cookies.get(CONSENT_COOKIES_KEYS.LOCATION) === "true";
        var accepted = Cookies.get(CONSENT_COOKIES_KEYS.CONSENT_SAVED) === "true";
        Cookies.set(CONSENT_COOKIES_KEYS.CONSENT_SAVED, accepted ? "true" : "false", advertisement && social && analytics && personalised_ads
            ? { expires: 9999 }
            : { expires: 7 });
        return {
            social: social,
            advertisement: advertisement,
            analytics: analytics,
            personalised_ads: personalised_ads,
            location: location,
            accepted: accepted,
        };
    },
};
export default Consents;
