var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import Cookies from "js-cookie";
import { EventHandler } from "../EventHandler";
var BitterbalHandler = /** @class */ (function (_super) {
    __extends(BitterbalHandler, _super);
    function BitterbalHandler() {
        var _this = _super.call(this, "bitterbal", ["accepted", "analytics"]) || this;
        _this._localStorageKey = "bitterbal";
        _this._sessionStorageKey = "bitterbal";
        _this._cookieUserIdKey = "bitterbalid";
        _this._cookieDomain = ".withlocals.com";
        _this._cookieExpires = Number.MAX_VALUE;
        _this._userId = _this._getUserId();
        _this._setUserIdCookie(_this._userId);
        _this._persistedVars = _this._getPersistedVars();
        _this._storePersistedVars(_this._persistedVars);
        _this._session = _this._getSession();
        _this._storeSession(_this._session);
        _this._startTimestamp = window.performance.now();
        return _this;
    }
    BitterbalHandler.prototype.canProcess = function () {
        var _a, _b;
        return !!((_a = window === null || window === void 0 ? void 0 : window.b) === null || _a === void 0 ? void 0 : _a.env) && !!((_b = window === null || window === void 0 ? void 0 : window.b) === null || _b === void 0 ? void 0 : _b.ep);
    };
    BitterbalHandler.prototype.process = function (event) {
        var _a;
        if (!((_a = window === null || window === void 0 ? void 0 : window.b) === null || _a === void 0 ? void 0 : _a.ep)) {
            return;
        }
        var payload = this._createPayload(event);
        fetch(window === null || window === void 0 ? void 0 : window.b.ep, {
            body: JSON.stringify(payload),
            method: "POST",
            mode: "cors",
            headers: { "Content-Type": "application/json" },
        }).catch(function (e) {
            var _a;
            console.warn("Bitterbal failed to send event", payload, "due to", (_a = e === null || e === void 0 ? void 0 : e.message) !== null && _a !== void 0 ? _a : "unknown error");
        });
    };
    BitterbalHandler.prototype._createPayload = function (event) {
        return {
            userId: this._userId,
            session: this._session,
            persisted: this._persistedVars,
            event: {
                event: event.name,
                payload: __assign({}, this._filterPrivateEventParams(event.params)),
            },
            time: window.performance.now() - this._startTimestamp,
        };
    };
    BitterbalHandler.prototype._getPersistedVars = function () {
        try {
            var persistedVars = window.localStorage.getItem(this._localStorageKey);
            if (persistedVars) {
                return JSON.parse(persistedVars);
            }
        }
        catch (error) {
            console.warn("Bitterbal failed to get persisted vars from local storage", error);
        }
        return {};
    };
    BitterbalHandler.prototype._storePersistedVars = function (persistedVars) {
        try {
            window.localStorage.setItem(this._localStorageKey, JSON.stringify(persistedVars));
        }
        catch (e) {
            console.warn("Bitterbal failed to store persisted vars", persistedVars, "error:", e);
        }
    };
    BitterbalHandler.prototype._getSession = function () {
        var _a;
        try {
            var sessionFromStorage = window.sessionStorage.getItem(this._sessionStorageKey);
            if (sessionFromStorage) {
                return JSON.parse(sessionFromStorage);
            }
        }
        catch (error) {
            console.warn("Bitterbal failed to get session", error);
        }
        return {
            sessionId: this._generateId(),
            environment: (_a = window === null || window === void 0 ? void 0 : window.b) === null || _a === void 0 ? void 0 : _a.env,
            platform: "web",
            version: "storefront-1.0.0",
        };
    };
    BitterbalHandler.prototype._storeSession = function (session) {
        try {
            window.sessionStorage.setItem(this._sessionStorageKey, JSON.stringify(session));
        }
        catch (error) {
            console.warn("Bitterbal failed to store session", session, "error:", error);
        }
    };
    BitterbalHandler.prototype._getUserId = function () {
        var userIdFromCookie = Cookies.get(this._cookieUserIdKey);
        if (userIdFromCookie) {
            return userIdFromCookie;
        }
        return this._generateId();
    };
    BitterbalHandler.prototype._setUserIdCookie = function (userId) {
        Cookies.set(this._cookieUserIdKey, userId, {
            domain: this._cookieDomain,
            secure: true,
            expires: this._cookieExpires,
        });
    };
    BitterbalHandler.prototype._generateId = function () {
        if (!crypto || !crypto.randomUUID) {
            return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
                var r = (Math.random() * 16) | 0;
                var v = c === "x" ? r : (r & 0x3) | 0x8;
                return v.toString(16);
            });
        }
        return crypto.randomUUID();
    };
    return BitterbalHandler;
}(EventHandler));
export { BitterbalHandler };
