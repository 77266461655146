import { GA4Handler } from "./GA4/GA4EventHandler";
import { PlausibleAnalyticsHandler } from "./PlausibleAnalytics/PlausibleAnalyticsHandler";
import { VeroEventHandler } from "./Vero/VeroEventHandler";
import { SnowplowHandler } from "./Snowplow/SnowplowHandler";
import { BitterbalHandler } from "./Bitterbal/BitterbalEventHandler";
var EventBus = /** @class */ (function () {
    function EventBus() {
        this.handlers = [];
        if (typeof window === "undefined") {
            return;
        }
        this._addHandler(new GA4Handler());
        this._addHandler(new PlausibleAnalyticsHandler());
        this._addHandler(new VeroEventHandler());
        this._addHandler(new BitterbalHandler());
        this._addHandler(new SnowplowHandler());
    }
    EventBus.prototype.dispatch = function (event) {
        console.log("[EventBus] received event:", event);
        this._cleanParams(event.params);
        this.handlers.forEach(function (handler) { return handler.handle(event); });
    };
    EventBus.prototype.flush = function (handlerId) {
        if (handlerId) {
            var handler = this.handlers.find(function (handler) { return handler.id === handlerId; });
            if (handler) {
                handler.flushBuffer();
            }
            return;
        }
        this.handlers.forEach(function (handler) { return handler.flushBuffer(); });
    };
    EventBus.prototype._cleanParams = function (params) {
        if (params) {
            Object.keys(params).forEach(function (key) {
                if ((params === null || params === void 0 ? void 0 : params[key]) === undefined ||
                    (params === null || params === void 0 ? void 0 : params[key]) === null ||
                    (params === null || params === void 0 ? void 0 : params[key]) === "") {
                    params === null || params === void 0 ? true : delete params[key];
                }
            });
        }
    };
    EventBus.prototype._addHandler = function (handler) {
        if (this.handlers.some(function (h) { return h.id === handler.id; })) {
            console.warn("[EventBus] handler already exists with id [".concat(handler.id, "], not adding."));
            return;
        }
        this.handlers.push(handler);
    };
    return EventBus;
}());
export var eventBus = new EventBus();
