"use client";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { EventHandler } from "../EventHandler";
var PlausibleAnalyticsHandler = /** @class */ (function (_super) {
    __extends(PlausibleAnalyticsHandler, _super);
    function PlausibleAnalyticsHandler() {
        return _super.call(this, "plausible", []) || this;
    }
    PlausibleAnalyticsHandler.prototype.canProcess = function () {
        return !!(window === null || window === void 0 ? void 0 : window.plausible);
    };
    PlausibleAnalyticsHandler.prototype.process = function (event) {
        var _a;
        var eventProps = this._filterPrivateEventParams(event.params);
        // Rename page_view event to Plausible's native pageview to
        // simply attach custom properties to the native event
        var eventName = event.name === "page_view" ? "pageview" : event.name;
        return (_a = window.plausible) === null || _a === void 0 ? void 0 : _a.call(window, eventName, {
            props: __assign(__assign({}, eventProps), { application: "storefront" }),
        });
    };
    return PlausibleAnalyticsHandler;
}(EventHandler));
export { PlausibleAnalyticsHandler };
