"use client";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { EventHandler } from "../EventHandler";
var VeroEventHandler = /** @class */ (function (_super) {
    __extends(VeroEventHandler, _super);
    function VeroEventHandler() {
        return _super.call(this, "vero", ["analytics", "accepted"]) || this;
    }
    VeroEventHandler.prototype.canProcess = function () {
        return !!(window === null || window === void 0 ? void 0 : window._veroq);
    };
    VeroEventHandler.prototype.process = function (event) {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        switch (event.name) {
            case "page_view":
                (_a = window._veroq) === null || _a === void 0 ? void 0 : _a.push([
                    "user",
                    {
                        id: (_b = event.params) === null || _b === void 0 ? void 0 : _b._userId,
                        email: (_c = event.params) === null || _c === void 0 ? void 0 : _c._userEmail,
                    },
                ]);
                return (_d = window._veroq) === null || _d === void 0 ? void 0 : _d.push(["trackPageview"]);
            case "experience_viewed":
                return (_e = window._veroq) === null || _e === void 0 ? void 0 : _e.push([
                    "track",
                    "experience_viewed",
                    {
                        SKU: (_f = event.params) === null || _f === void 0 ? void 0 : _f.experience_id,
                        country: (_g = event.params) === null || _g === void 0 ? void 0 : _g.country,
                        city: (_h = event.params) === null || _h === void 0 ? void 0 : _h.area,
                    },
                ]);
        }
    };
    return VeroEventHandler;
}(EventHandler));
export { VeroEventHandler };
