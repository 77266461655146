var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import Consents from "./Consents";
var EventHandler = /** @class */ (function () {
    function EventHandler(id, requiredConsents) {
        this.id = id;
        this._buffer = [];
        this._requiredConsents = requiredConsents;
    }
    EventHandler.prototype.handle = function (event) {
        console.log("[handler-".concat(this.id, "]"), "received event:", event);
        if (!this.hasConsent() || !this.canProcess()) {
            console.log("[handler-".concat(this.id, "]"), "cannot process event:", event, "buffering...");
            this.buffer(event);
            return;
        }
        this.process(event);
    };
    EventHandler.prototype.hasConsent = function () {
        var consents = Consents.get();
        return this._requiredConsents.every(function (consent) { return consents[consent]; });
    };
    EventHandler.prototype.buffer = function (event) {
        this._buffer.push(event);
    };
    EventHandler.prototype.flushBuffer = function () {
        var _this = this;
        console.log("[handler-".concat(this.id, "]"), "flushing buffer:", this._buffer);
        this._buffer.forEach(function (event) { return _this.handle(event); });
        this._buffer = [];
    };
    EventHandler.prototype._filterPrivateEventParams = function (params) {
        var newParams = __assign({}, params);
        Object.keys(newParams).forEach(function (key) {
            if (key.startsWith("_")) {
                delete newParams[key];
            }
        });
        return newParams;
    };
    return EventHandler;
}());
export { EventHandler };
